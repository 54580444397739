import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import { StoreProvider } from "./context/StoreContext";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeScreen1 from "./screens/HomeScreen1";
import ProductScreen from "./screens/ProductScreen";
import ScrollToTop from "./components/ScrollToTop";
import AboutUsScreen from "./screens/AboutUsScreen";
import ContactUsScreen from "./screens/ContactUsScreen";
import ProductVerificationScreen from "./screens/ProductVerificationScreen";
import InnovationScreen from "./screens/InnovationScreen";
import BlogScreen from "./screens/BlogScreen";
// import Navbar from "./components/Navbar";
// import MobileMenu from "./components/MobileMenu";

function App() {
  // const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // const openMobileMenu = () => {
  //   setIsMobileMenuOpen(true);
  // };

  // const closeMobileMenu = () => {
  //   setIsMobileMenuOpen(false);
  // };

  return (
    <StoreProvider>
      <BrowserRouter>
        <div id="main-wrapper">
          {/* <Navbar /> */}
          {/* <MobileMenu isOpen={isMobileMenuOpen} onClose={closeMobileMenu} /> */}
          <Header />
          <ScrollToTop />
        </div>
        <main>
          <Routes>
            <Route path="/" element={<HomeScreen />} exact></Route>
            <Route path="/1" element={<HomeScreen1 />} exact></Route>
            <Route path="/about-us" element={<AboutUsScreen />} exact></Route>
            <Route
              path="/contact-us"
              element={<ContactUsScreen />}
              exact
            ></Route>
            <Route path="/product" element={<ProductScreen />} exact></Route>
            <Route
              path="/product-verification"
              element={<ProductVerificationScreen />}
              exact
            ></Route>
            <Route
              path="/innovation"
              element={<InnovationScreen />}
              exact
            ></Route>
            <Route path="/blogs" element={<BlogScreen />} exact></Route>
          </Routes>
        </main>
        <Footer />
      </BrowserRouter>
    </StoreProvider>
  );
}

// import Modal from './components/Modal/Modal'

// const App = () => {
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const openModal = () => {
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   return (
//     <div>
//       <h1>React Modal Example</h1>
//       <button onClick={openModal}>Open Modal</button>
//       <Modal isOpen={isModalOpen} onClose={closeModal} />
//     </div>
//   );
// };

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

export default App;
