import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const AboutUsScreen = () => {
  const [isMobile, setIsMobile] = useState(getDeviceType());
  function getDeviceType() {
    return window.innerWidth > 768 ? false : true;
  }
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(getDeviceType());
    };

    // Attach the event listener for resize
    window.addEventListener("resize", handleResize);

    // Cleanup: remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <div className="bg-1">
        <section
          className="middle"
          // style={{ background: "#000000 0% 0% no-repeat padding-box" }}
        >
          <div className="container">
            <div className="row text-center" style={{ paddingTop: "40px" }}>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <h2
                  className="text-theme2 font-Horizon"
                  style={{ fontSize: isMobile ? "23px" : "60px" }}
                >
                  ABOUT US
                </h2>
              </div>
            </div>

            <div
              className="row align-items-center justify-content-between"
              style={{ paddingTop: "100px" }}
            >
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div
                  className="first_about_border"
                  style={{ height: "147%" }}
                ></div>
                <div className="abt_caption first_about">
                  <h2 className="ft-medium mb-4 font-Horizon text-theme3">
                    WHERE IT ALL BEGAN!
                  </h2>
                  <p className="mb-4" style={{ color: "#FFFFFF" }}>
                    A bunch of friends, back in 2016, dreaming of a better way
                    to enjoy nicotine. We weren’t experts. We were just regular
                    folks, with rebellious souls and a shared vision for a
                    healthier, tastier, and easier way to curb our nicotine
                    cravings. That’s when Dared2 came to life. Fast forward to
                    today, and we’ve grown into a coveted name in the vaping
                    world.
                  </p>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="abt_caption ">
                  <img
                    src="/assets/images/banner/Group 197-1.png"
                    className="img-fluid rounded"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="middle"
          // style={{ background: "#000000 0% 0% no-repeat padding-box" }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="abt_caption abt_caption_image">
                  <img
                    src="/assets/images/banner/Group 205-1.png"
                    className="img-fluid rounded"
                    alt=""
                  />
                </div>
              </div>

              <div
                className="col-xl-6 col-lg-6 col-md-6 col-sm-12"
                style={{ marginTop: isMobile && "20px" }}
              >
                <div
                  className="second_about_border"
                  style={{ height: "160%" }}
                ></div>
                <div className="abt_caption second_about">
                  <h2 className="ft-medium mb-4 font-Horizon text-theme2">
                    OUR COMMITMENT
                  </h2>
                  <p className="mb-4" style={{ color: "#FFFFFF" }}>
                    At DARED2, we’re all about excellence in vaping. From easy
                    starter kits to advanced mods and custom e-liquids, we’ve
                    got something for anyone who dares to think differently and
                    challenge the norms. Rest assured, your vaping journey will
                    get a whole lot cooler with us, and who knows, even life
                    changing.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="row align-items-center justify-content-between"
              style={{ paddingTop: "100px" }}
            >
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div
                  className="third_about_border"
                  style={{ height: "160%" }}
                ></div>
                <div className="abt_caption first_about">
                  <h2 className="ft-medium mb-4 font-Horizon text-theme">
                    OUR VALUES
                  </h2>
                  <p className="mb-4" style={{ color: "#FFFFFF" }}>
                    Think of us as your trusted comrades on your vaping journey.
                    We’re all about keeping it real through integrity,
                    transparency, and making sure you never leave unhappy. We
                    source our ingredients from trusted suppliers, ensuring that
                    every puff you take is free from harmful chemicals and
                    contaminants.
                  </p>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="abt_caption ">
                  <img
                    src="/assets/images/banner/Group 243.png"
                    className="img-fluid rounded"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="middle pb-20"
          // style={{ background: "#000000 0% 0% no-repeat padding-box" }}
        >
          <div className="container">
            <div className="row join__the">
              {!isMobile && (
                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                  <img
                    src="/assets/images/banner/Group 248.png"
                    className="about_left_img"
                  />
                </div>
              )}

              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                <h2
                  className="text-theme2 font-Horizon text-center"
                  style={{
                    fontSize: isMobile ? "18px" : "40px",
                    marginTop: "20px",
                  }}
                >
                  JOIN THE DARED2 CLAN!
                </h2>
                <br />
                <p className="text-white text-center">
                  If you’ve read this far, the rebel in you must have found it
                  worthwhile. Wait no more! Unleash that rebel, and join us in
                  revolutionizing the vaping experience. Give us a call, and
                  let’s talk business!
                </p>
                <p className="text-theme3 text-center">
                  Think freely, vape responsibly and savor the flavor.
                </p>
              </div>
              {!isMobile && (
                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                  <img
                    src="/assets/images/banner/Group 249.png"
                    className="about_right_img"
                  />
                </div>
              )}
            </div>
          </div>
        </section>

        <section
          className="middle pb-20"
          // style={{ background: "#000000 0% 0% no-repeat padding-box" }}
        >
          <div className="container">
            <div className="row text-center" style={{ paddingTop: "40px" }}>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <h2
                  className="text-theme2 font-Horizon"
                  style={{ fontSize: isMobile ? "23px" : "60px" }}
                >
                  Warning
                </h2>
              </div>
              <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1"></div>
              <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10">
                <br />
                <p className="text-white">
                  Not for Non-Smokers and Minors: This product is intended for
                  use by current adult smokers only. It is not suitable for
                  nonsmokers, pregnant or breastfeeding women, or individuals
                  with pre-existing medical conditions. hazards and promote
                  responsible use.
                </p>
              </div>
              <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1"></div>
            </div>

            <div
              className="row"
              style={{
                paddingTop: "40px",
                paddingBottom: "50px",
                color: "#fff",
              }}
            >
              <ul className="w-ul">
                <li>
                  <strong style={{ color: "#FF0000" }}>Health Risks:</strong>{" "}
                  The use of this product contains nicotine, a highly addictive
                  substance. Vaping can lead to various health risks, including
                  but not limited to respiratory problems, heart disease, and
                  addiction.
                </li>
                <li>
                  <strong style={{ color: "#FF0000" }}>
                    Keep Out of Reach:
                  </strong>{" "}
                  Keep this product out of the reach of children and pets.
                  Nicotine poisoning can occur if ingested or exposed to the
                  skin.
                </li>
                <li>
                  <strong style={{ color: "#FF0000" }}>
                    Chemical Exposure:
                  </strong>{" "}
                  Vaping can expose users to harmful chemicals, including those
                  that may be carcinogenic or toxic. Avoid direct skin contact
                  with e-liquids and handle them with care.
                </li>
                <li>
                  <strong style={{ color: "#FF0000" }}>Usage Caution:</strong>{" "}
                  Do not use this product while driving or operating heavy
                  machinery, as it may impair coordination and reaction time.
                </li>
                <li>
                  <strong style={{ color: "#FF0000" }}>
                    Potential Allergic Reactions:
                  </strong>{" "}
                  Some individuals may be sensitive or allergic to certain
                  components of e-liquids. If you experience any adverse
                  reactions, discontinue use immediately and seek medical
                  attention if necessary.
                </li>
                <li>
                  <strong style={{ color: "#FF0000" }}>
                    Health Consultation:
                  </strong>{" "}
                  Consult with a healthcare professional before using this
                  product, especially if you have underlying health conditions
                  or are taking medications.
                </li>
                <li>
                  <strong style={{ color: "#FF0000" }}>Proper Disposal:</strong>{" "}
                  Dispose of used e-liquid containers, batteries, and vaping
                  devices in accordance with local regulations and guidelines.
                </li>
                <li>
                  <strong style={{ color: "#FF0000" }}>Battery Safety:</strong>{" "}
                  The battery used in this device can be hazardous if damaged or
                  misused. Do not puncture, disassemble, or expose the battery
                  to extreme temperatures.
                </li>
                <li>
                  <strong style={{ color: "#FF0000" }}>Product Quality:</strong>{" "}
                  Purchase vaping products from reputable sources to ensure
                  quality and safety standards. Remember, the best way to reduce
                  health risks associated with vaping is to quit or never start
                  using nicotine-containing products. This warning label aims to
                  inform users about potential
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default AboutUsScreen;
