import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const ContactUsScreen = () => {
  const [isMobile, setIsMobile] = useState(getDeviceType());
  function getDeviceType() {
    return window.innerWidth > 768 ? false : true;
  }
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(getDeviceType());
    };

    // Attach the event listener for resize
    window.addEventListener("resize", handleResize);

    // Cleanup: remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <div className="bg-1">
        <section
          className="middle"
          style={{ background: "#000000 0% 0% no-repeat padding-box" }}
        >
          <div className="container bg-contact-us">
            <div
              className="row text-center"
              style={{ paddingTop: isMobile ? "15vh" : "60vh" }}
            >
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <h2
                  className="ft-medium mb-4 font-Horizon text-theme"
                  style={{
                    fontSize: isMobile ? "24px" : "60px",
                    marginTop: "20px",
                    letterSpacing: isMobile ? "0px" : "6px",
                  }}
                >
                  Dare-d2 SUPPORT
                  {!isMobile && <br />}
                  <br />
                  IS HERE FOR YOU!
                </h2>
                {/* <img
                  src="/assets/images/banner/contact-us-banner.png"
                  className="img-fluid"
                /> */}
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="bg-contact-1">
        <section
          className="middle"
          style={{ background: "#000000 0% 0% no-repeat padding-box" }}
        >
          <div className="container contact-2__">
            <div className="row text-center">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <img src="/assets/images/contact-3.png" className="img-fluid" />
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="bg-1">
        <section
          className="middle"
          style={{ background: "#000000 0% 0% no-repeat padding-box" }}
        >
          <div className="container contact-2__">
            <div className="row text-center">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <img
                  src="/assets/images/contact-2.png"
                  className="img-fluid"
                  style={{ borderRadius: "15px" }}
                />
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="bg-1">
        <section
          className="middle"
          style={{ background: "#000000 0% 0% no-repeat padding-box" }}
        >
          <div
            className="container"
            style={{
              background: "#1D1D1D 0% 0% no-repeat padding-box",
              // border: "1px solid #CEC9C9",
              borderRadius: "15px",
              height: "100%",
              width: !isMobile ? "60%" : "",
            }}
          >
            <div className="row text-center">
              <div
                className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-left"
                style={{ padding: "40px" }}
              >
                <h4
                  className="text-white"
                  style={{ fontWeight: "bold", paddingBottom: "20px" }}
                >
                  ENQUIRE
                </h4>
                <div style={{ paddingBottom: "20px" }}>
                  <input
                    type="text"
                    placeholder="Name"
                    autoComplete="off"
                    style={{ width: "100%" }}
                  />
                </div>
                <div style={{ paddingBottom: "20px" }}>
                  <input
                    type="text"
                    placeholder="Mob. Number"
                    autoComplete="off"
                    style={{ width: "100%" }}
                  />
                </div>
                <div style={{ paddingBottom: "20px" }}>
                  <input
                    type="text"
                    placeholder="Email Address"
                    autoComplete="off"
                    style={{ width: "100%" }}
                  />
                </div>
                <div style={{ paddingBottom: "20px" }}>
                  <textarea
                    placeholder="Message"
                    rows={10}
                    cols={10}
                    style={{ width: "100%" }}
                  ></textarea>
                </div>
                <div>
                  <button
                    type="submit"
                    className="input-group-text b-0 btn_subscribe"
                    style={{ borderRadius: "25px", color: "#000" }}
                  >
                    SEND
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default ContactUsScreen;
