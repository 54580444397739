import React, { createContext, useReducer, useContext } from 'react';
import { CounterReducer } from '../reducers/CounterReducer';

// Initial state
const initialState = {
  counter: 0,
};

// Create a context
const StoreContext = createContext();

// Create a provider component
export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CounterReducer, initialState);

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

// Create a custom hook to consume the context
export const useStore = () => {
  const context = useContext(StoreContext);
  if (!context) {
    throw new Error('useStore must be used within a StoreProvider');
  }
  return context;
};
