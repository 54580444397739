import React, { useState, useEffect } from "react";
//import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";

const Footer = () => {
  const [isMobile, setIsMobile] = useState(getDeviceType());
  const [learnMore, setLearnMore] = useState(false);
  function getDeviceType() {
    return window.innerWidth > 768 ? false : true;
  }
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(getDeviceType());
    };

    // Attach the event listener for resize
    window.addEventListener("resize", handleResize);

    // Cleanup: remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <footer className="grocery-footer skin-grocery-footer style-2">
        <div className="footer-middle">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 custom_margin_for_mobile">
                <div className="footer_widget">
                  <h4 className="widget_title text-light">SUBSCRIBE</h4>
                  <p>
                    Get the latest product launches, promotions, and contest
                    delivered
                    <br />
                    Straight to your inbox for free!
                  </p>
                  <form className="form" style={{ maxWidth: "400px" }}>
                    <div className="foot-news-last">
                      <div
                        className="input-group"
                        style={{ background: "#fff", borderRadius: "25px" }}
                      >
                        <input
                          type="email"
                          id="email"
                          placeholder="Email Address"
                          className="form-control txt_subscribe"
                          autoComplete="off"
                        ></input>

                        <div
                          className="input-group-append"
                          style={{
                            // background: "#4CD9FF",
                            borderRadius: "25px",
                          }}
                        >
                          <button
                            type="submit"
                            className="input-group-text b-0 btn_subscribe"
                            style={{ borderRadius: "25px", color: "#000" }}
                          >
                            SIGN UP
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: isMobile ? "20px" : "" }}>
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                <div className="footer_widget">
                  <h4 className="widget_title text-light">PRODUCTS</h4>
                  <ul className="footer-menu">
                    <li>
                      <Link to="/">POD</Link>
                    </li>
                    <li>
                      <Link to="/">POD MOD</Link>
                    </li>
                    <li>
                      <Link to="/">TANK MOD</Link>
                    </li>
                    <li>
                      <Link to="/">PEN STYLE</Link>
                    </li>
                  </ul>
                </div>
              </div>

              {/* <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-4">
                <div className="footer_widget">
                  <h4 className="widget_title text-light">PLATFORM</h4>
                  <ul className="footer-menu">
                    <li>
                      <Link to="/">ZERO POD</Link>
                    </li>
                    <li>
                      <Link to="/">XROS MOD</Link>
                    </li>
                    <li>
                      <Link to="/">ITANK</Link>
                    </li>
                    <li>
                      <Link to="/">COIL</Link>
                    </li>
                    <li>
                      <Link to="/">OTHERS</Link>
                    </li>
                  </ul>
                </div>
              </div> */}

              {/* <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-4">
                <div className="footer_widget">
                  <h4 className="widget_title text-light">COMLIANCE</h4>
                  <ul className="footer-menu">
                    <li>
                      <Link to="/">POWER SHOP</Link>
                    </li>
                    <li>
                      <Link to="/">DISTRIBUTOR</Link>
                    </li>
                    <li>
                      <Link to="/">NEWS & EVENTS</Link>
                    </li>
                    <li>
                      <Link to="/">BLOG</Link>
                    </li>
                    <li>
                      <Link to="/">STORE LOCATOR</Link>
                    </li>
                  </ul>
                </div>
              </div> */}

              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                <div className="footer_widget">
                  <h4 className="widget_title text-light">SUPPORT</h4>
                  <ul className="footer-menu">
                    <li>
                      <Link to="/">WARRANTY</Link>
                    </li>
                    <li>
                      <Link to="/">CONTACT US</Link>
                    </li>
                    <li>
                      <Link to="/">MANUALS & MEDIA KITS</Link>
                    </li>
                    {/* <li>
                      <Link to="/">PRODUCT VERIFICATION</Link>
                    </li> */}
                    <li>
                      <Link to="/">FAQ</Link>
                    </li>
                    {/* <li>
                      <Link to="/">VAPORESSO COSS SERVICE</Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 custom_margin_for_mobile">
                <div className="footer_widget">
                  <h4 className="widget_title text-light">CONTACT US</h4>
                  <ul className="footer-menu">
                    <li>
                      <Link to="http://www.dared2.co/">www.dared2.co</Link>
                    </li>
                    <li>
                      <Link to="mailto:support@dared2.co">
                        support@dared2.co
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 custom_margin_for_mobile float-right">
                <div className="footer_widget">
                  <h4 className="widget_title text-light">FOLLOW US</h4>
                  <p>
                    Are you social, releasing new products, or holding an event?
                    <br />
                    Follow us to find out more.
                  </p>
                  <form className="form">
                    <div className="foot-news-last">
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <a href="#!" rel="noreferrer">
                            <img
                              src="/assets/images/facebook.svg"
                              alt="facebook"
                            />
                            {/* <i className="lni lni-facebook-filled"></i> */}
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="#!" rel="noreferrer">
                            <img
                              src="/assets/images/insta.svg"
                              alt="instagram"
                            />
                            {/* <i className="lni lni-instagram-filled"></i> */}
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="#!" rel="noreferrer">
                            <img
                              src="/assets/images/twitter.svg"
                              alt="twitter"
                            />
                            {/* <i className="lni lni-twitter-filled"></i> */}
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="#!" rel="noreferrer">
                            <img
                              src="/assets/images/youtube.svg"
                              alt="youtube"
                            />
                            {/* <i className="lni lni-youtube"></i> */}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 col-md-12 text-left">
                <p
                  className="mb-0"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setLearnMore(!learnMore);
                  }}
                >
                  Learn More {">"}
                </p>
                {learnMore ? (
                  <>
                    <p>&nbsp;</p>
                    <p style={{ paddingBottom: "50px" }}>
                      Vaporesso e-cigarette devices are intended for use with
                      e-liquids, which may contain nicotine. Nicotine is an
                      addictive chemical. Do not use with any other substances.
                      Do not get on skin or in eyes. Do not drink. Store in
                      original container and keep away from children and pets.
                      This product is intended for adult users of
                      nicotine-containing products, particularly current smokers
                      or vapers. Underage sale is prohibited. Do not use this
                      product if you:
                      <ul>
                        <li>Are under the legal age of purchase</li>
                        <li>Are pregnant or breastfeeding</li>
                        <li>
                          Have heart disease, stomach or duodenal ulcers, liver
                          or kidney problems, throat disease, or difficulty
                          breathing due to bronchitis, emphysema, or asthma
                        </li>
                        <li>
                          Have an overactive thyroid or pheochromocytoma (a
                          tumor of the adrenal gland that can affect blood
                          pressure)
                        </li>
                        <li>
                          Are taking certain medications, such as theophylline,
                          ropinirole, or clozapine
                        </li>
                      </ul>
                    </p>
                  </>
                ) : null}

                {/* <p>
                  CALIFORNIA PROPOSITION 65 - Warning: This product can expose
                  you to chemicals including formaldehyde and acetaldehyde which
                  known to the State of California to cause cancer, and
                  Nicotine, which is known to the State of California to cause
                  birth defects or other reproductive harm. For more information
                  go to{" "}
                  <a href="http://www.P65Warnings.ca.gov ">
                    www.P65Warnings.ca.gov{" "}
                  </a>
                </p>
                {isMobile ? (
                  <p>
                    Copyright &copy; 2022 Vaporesso. All rights reserved.
                    <br />
                    Privacy | Terms &amp; Conditions | Cookies policy
                  </p>
                ) : (
                  <p>
                    Copyright &copy; 2022 Vaporesso. All rights reserved.
                    Privacy | Terms &amp; Conditions | Cookies policy
                  </p>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
