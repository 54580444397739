import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
// import { isMobile } from "react-device-detect";

const Header = () => {
  const location = useLocation();

  const [isMobile, setIsMobile] = useState(getDeviceType());
  function getDeviceType() {
    return window.innerWidth > 768 ? false : true;
  }
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(getDeviceType());
    };

    // Attach the event listener for resize
    window.addEventListener("resize", handleResize);

    // Cleanup: remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  $(document).ready(() => {
    $(".nav-menus-wrapper-close-button").on("click", () => {
      setIsMobileMenuOpen(false);
    });
  });
  return (
    <>
      <div className="grocery-bg">
        <div className="container">
          <div className="row">
            {/* <div className="col-xl-4 col-lg-2 col-md-2 col-sm-6 col-4">
              <img src="/assets/images/warning-icon.png" alt="" width={100} />
              This product contains nicotine.
              <br />
              Which is a highly addictive substance
            </div>
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-4"></div>
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-4"></div>
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-4"></div>
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-4"></div> */}
          </div>
        </div>
      </div>
      <div>
        <div
          className="header header-dark dark-text"
          // style={{ height: "50px" }}
        >
          <div>
            <img
              src="/assets/images/top-header-icon.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="container width__100per">
            <nav id="navigation" className="navigation navigation-landscape">
              <div className="nav-header">
                <a
                  className={isMobile ? "nav-brand text-center" : "nav-brand"}
                  href="/"
                >
                  <img src="/assets/images/logo.png" className="logo" alt="" />
                </a>
                <div
                  className="nav-toggle"
                  onClick={() => {
                    setIsMobileMenuOpen(true);
                  }}
                ></div>
                <div className="mobile_nav">
                  {/* <ul>
                    <li>
                      <a href="#!">
                        <i className="lni lni-search-alt"></i>
                      </a>
                    </li>
                    <li>
                      <Link to="/orderhistory">
                        <i className="lni lni-user"></i>
                      </Link>
                    </li>
                  </ul> */}
                </div>
              </div>
              <div
                className="nav-menus-wrapper"
                style={{
                  transitionProperty: isMobileMenuOpen && "left",
                  left: isMobileMenuOpen && "0",
                }}
              >
                <ul className="nav-menu">
                  <li
                    style={{ marginLeft: !isMobile && "150px" }}
                    className={location.pathname === "/" ? "active" : ""}
                  >
                    <a href="/">HOME</a>
                  </li>

                  <li
                    className={location.pathname === "/product" ? "active" : ""}
                    onClick={() => {
                      $(".nav-menus-wrapper-close-button").click();
                    }}
                  >
                    <Link to="/product">PRODUCT</Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/about-us" ? "active" : ""
                    }
                    onClick={() => {
                      $(".nav-menus-wrapper-close-button").click();
                    }}
                  >
                    <Link to="/about-us">ABOUT</Link>
                  </li>
                  {/* <li>
                    <Link to="/">VAPES</Link>
                  </li> */}
                  <li
                    className={
                      location.pathname === "/innovation" ? "active" : ""
                    }
                    onClick={() => {
                      $(".nav-menus-wrapper-close-button").click();
                    }}
                  >
                    <Link to="/innovation">INNOVATION</Link>
                  </li>
                  <li
                    className={location.pathname === "/blogs" ? "active" : ""}
                    onClick={() => {
                      $(".nav-menus-wrapper-close-button").click();
                    }}
                  >
                    <Link to="/blogs">BLOG</Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/contact-us" ? "active" : ""
                    }
                    onClick={() => {
                      $(".nav-menus-wrapper-close-button").click();
                    }}
                  >
                    <Link to="/contact-us">CONTACT</Link>
                  </li>
                </ul>

                {/* <ul className="nav-menu">
                  <li style={{ marginLeft: "50px" }}>
                    <Link to="/">HOME</Link>
                  </li>

                  <li>
                    <Link to="/our-story">PRODUCT</Link>
                  </li>
                  <li>
                    <Link to="/blog">OUR STORY</Link>
                  </li>
                  <li>
                    <Link to="/">ABOUT US</Link>
                  </li>
                  <li>
                    <Link to="/">AUTHENTICATION</Link>
                  </li>
                  <li>
                    <Link to="/">WHY DARED2</Link>
                  </li>
                  <li>
                    <Link to="/">BLOG</Link>
                  </li>
                </ul> */}
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
