import React, { useState, useEffect } from "react";
const ProductVerificationScreen = () => {
  const [isMobile, setIsMobile] = useState(getDeviceType());
  const [productStatus, setProductStatus] = useState("");
  const [isProductValid, setIsProductValid] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");

  function getDeviceType() {
    return window.innerWidth > 768 ? false : true;
  }
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(getDeviceType());
    };

    // Attach the event listener for resize
    window.addEventListener("resize", handleResize);

    // Cleanup: remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    setProductStatus("");
    if (validate()) {
      if (
        code.toUpperCase() === "TEST1" ||
        code.toUpperCase() === "TEST2" ||
        code.toUpperCase() === "TEST3" ||
        code.toUpperCase() === "TEST4"
      ) {
        setIsProductValid(true);
        setProductStatus("valid");
      } else {
        setIsProductValid(false);
        setProductStatus("invalid");
      }
    }
    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth",
    // });
  };

  const validate = () => {
    setCodeError("");
    let _isOk = true;

    if (code === "") {
      _isOk = false;
      setCodeError(
        "Please enter the 14-digit or 12-digit authentic verification code"
      );
    }
    return _isOk;
  };
  return (
    <>
      <div className="bg-product-verification">
        <section
          className="middle"
          // style={{ background: "#000000 0% 0% no-repeat padding-box" }}
        >
          <div className="container">
            <div
              className="row text-center"
              style={{ paddingTop: isMobile ? "0" : "35vh" }}
            >
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <h2
                  className="ft-medium mb-4 font-Horizon text-theme"
                  style={{
                    fontSize: isMobile ? "24px" : "60px",
                    marginTop: "20px",
                  }}
                >
                  IS YOUR
                  {!isMobile && <br />}
                  {!isMobile && <br />}
                  Dare-D2 PRODUCT
                  {!isMobile && <br />}
                  <br />
                  AUTHENTIC?
                </h2>
                <p className="text-white">
                  Dared2 has upgraded the verification code system from the
                  previous 12-digit security code to the new 14-digit security
                  code for better anti-counterfeiting from year 2020. The 2
                  versions of security code may still be in the market at the
                  same time. Find your security code and follow below to verify
                </p>
                {/* <img
                  src="/assets/images/banner/contact-us-banner.png"
                  className="img-fluid"
                /> */}
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="product-bg-1">
        <section
          className="middle"
          // style={{ background: "#000000 0% 0% no-repeat padding-box" }}
        >
          <div className="container contact-2__">
            <div className="row text-center">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <h3 className="text-white font-bold">
                  FIND THE AUTHENTIC VERIFICATION CODE
                </h3>
              </div>
            </div>
            <div className="row text-center" style={{ paddingTop: "40px" }}>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <h3 className="text-theme font-bold">HOW TO VERIFY</h3>
              </div>
            </div>
            <div className="row " style={{ paddingTop: "40px" }}>
              <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12"></div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                <div
                  style={{
                    background: "#606060 0% 0% no-repeat padding-box",
                    border: "1px solid #CEC9C9",
                    borderRadius: "15px",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      width: !isMobile ? "90%" : "100%",
                      padding: "20px",
                    }}
                  >
                    <ul style={{ listStyle: "number", color: "#fff" }}>
                      <li>
                        <p className="text-white">
                          Please enter the 14-digit or 12-digit authentic
                          verification code below:
                        </p>

                        <div
                          className="input-group"
                          style={{
                            background: "#fff",
                            borderRadius: "25px",
                            width: !isMobile ? "70%" : "",
                          }}
                        >
                          <input
                            type="text"
                            id="code"
                            placeholder="Enter Code"
                            className="form-control txt_subscribe"
                            autoComplete="off"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                          ></input>

                          <div
                            className="input-group-append"
                            style={{
                              // background: "#4CD9FF",
                              borderRadius: "25px",
                            }}
                          >
                            <button
                              type="submit"
                              className="input-group-text b-0 btn_verify"
                              style={{
                                borderRadius: "25px",
                                color: "#000",
                                cursor: "pointer",
                              }}
                              onClick={submitHandler}
                            >
                              VERIFY
                            </button>
                          </div>
                        </div>
                        <small className="text-white text-left">
                          * Please do not enter more than 5 times, or the code
                          will be invalid.
                        </small>
                        <br />
                        <span className="text-danger">{codeError}</span>
                      </li>
                      {productStatus !== "" && (
                        <li style={{ paddingTop: "30px" }}>
                          <p className="text-white">Verification Result</p>
                          <div
                            style={{
                              display: isMobile ? "flex" : "",
                              marginLeft: isMobile ? "-15px" : "",
                            }}
                          >
                            {isProductValid && (
                              <button
                                type="submit"
                                className="input-group-text b-0 btn_verify"
                                style={{
                                  borderRadius: "25px",
                                  color: "#000",
                                  width: "150px",
                                }}
                              >
                                Product is Verified
                              </button>
                            )}

                            {!isProductValid && (
                              <button
                                type="submit"
                                className="input-group-text b-0 btn_invalid"
                                style={{
                                  borderRadius: "25px",
                                  color: "#000",
                                  width: "180px",
                                }}
                              >
                                Product is Not Verified
                              </button>
                            )}
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12"></div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default ProductVerificationScreen;
