import React, { useEffect, useState } from "react";

// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
// import Modal from "../components/Modal/Modal";
// import { isMobile } from "react-device-detect";

const HomeScreen = () => {
  // const bannerOptions = {
  //   loop: true,
  //   center: true,
  //   items: 1,
  //   margin: 0,
  //   autoplay: true,
  //   dots: false,
  //   autoplayTimeout: 8500,
  //   smartSpeed: 450,
  //   nav: false,
  //   responsive: {
  //     0: {
  //       items: 1,
  //     },
  //     600: {
  //       items: 1,
  //     },
  //     1000: {
  //       items: 1,
  //     },
  //   },
  // };

  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const openModal = () => {
  //   setIsModalOpen(true);
  // };

  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  const [isMobile, setIsMobile] = useState(getDeviceType());
  function getDeviceType() {
    return window.innerWidth > 768 ? false : true;
  }
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(getDeviceType());
    };

    // Attach the event listener for resize
    window.addEventListener("resize", handleResize);

    // Cleanup: remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("selectedAge"))
      document.getElementById("a_mdlPopup").click();
    //setIsModalOpen(true);
  }, []);

  const handleAge = () => {
    localStorage.setItem("selectedAge", "Y");
  };

  return (
    <>
      {/* <OwlCarousel className="owl-carousel owl-theme" {...bannerOptions}>
        <div className="item no__padding">
          <img src="/assets/images/banner/banner-1.jpg" alt="banner" />
        </div>
      </OwlCarousel> */}

      <div className="home-slider hide-navigation margin-bottom-0">
        <div
          data-background-image="/assets/images/banner/home-banner-1.png"
          className="item"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="home-slider-container">
                  <div className="home-slider-desc">
                    <div className="home-slider-title mb-4">
                      <h2 className="text-theme font-Horizon">
                        SOMETHING DRAGGED YOU HERE.
                        <br />
                        AND WE KNOW IT WASN’T
                        <br />
                        THE CHAINS OF SOCIETY.
                      </h2>
                      <h3 className="text-white">&nbsp;</h3>

                      <span className="text-white">JOIN THE DARED2 CLAN</span>
                    </div>
                    <Link
                      to="/product"
                      className="btn stretched-link borders explore-btn"
                    >
                      EXPLORE
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          data-background-image="/assets/images/banner/home-banner-2.png"
          className="item"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="home-slider-container">
                  <div className="home-slider-desc">
                    <div className="home-slider-title mb-4">
                      <h2 className="text-theme2 font-Horizon">
                        HOW OFTEN DO WE GET TO
                        <br />
                        BE THE ARCHITECTS OF
                        <br />A CULTURES?
                      </h2>
                      <h3 className="text-white">&nbsp;</h3>

                      <span className="text-white">JOIN THE DARED2 CLAN</span>
                    </div>
                    <Link
                      to="/product"
                      className="btn stretched-link borders explore-btn"
                    >
                      EXPLORE
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          data-background-image="/assets/images/banner/home-banner-3.png"
          className="item"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="home-slider-container">
                  <div className="home-slider-desc">
                    <div className="home-slider-title mb-4">
                      <h2 className="text-theme3 font-Horizon">
                        YOU THINK THEREFORE
                        <br />
                        YOU ARE...
                        <br />
                        HERE...
                      </h2>
                      <h3 className="text-white">&nbsp;</h3>

                      <span className="text-white">JOIN THE DARED2 CLAN</span>
                    </div>
                    <Link
                      to="/product"
                      className="btn stretched-link borders explore-btn"
                    >
                      EXPLORE
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-1">
        <section
          className="middle"
          style={{ background: "#000000 0% 0% no-repeat padding-box" }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="first_about_border"></div>
                <div className="abt_caption first_about">
                  <h2 className="ft-medium mb-4 font-Horizon text-theme3">
                    WHAT DO WE
                    <br />
                    STAND FOR?
                  </h2>
                  <p className="mb-4" style={{ color: "#FFFFFF" }}>
                    It’s simple. You! And everything you stand for. Whether it’s
                    a recipe that you believe will inspire a new taste or a
                    life-altering message that you believe would inspire
                    generations, the Dared2 Clan welcomes all those who dare to
                    shake things up. Making fancy vape apparatuses isn’t so much
                    on our mind (although we do it spectacularly well), as it is
                    to give you a symbol of defiance against the norms.
                    Something you can hold on to as you go on to change the
                    world.
                  </p>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="abt_caption ">
                  <img
                    src="/assets/images/banner/Group 197.png"
                    className="img-fluid rounded"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="middle"
          style={{ background: "#000000 0% 0% no-repeat padding-box" }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="abt_caption abt_caption_image">
                  <img
                    src="/assets/images/banner/Group 205.png"
                    className="img-fluid rounded"
                    alt=""
                  />
                </div>
              </div>

              <div
                className="col-xl-6 col-lg-6 col-md-6 col-sm-12"
                style={{ marginTop: isMobile && "20px" }}
              >
                <div className="second_about_border"></div>
                <div className="abt_caption second_about">
                  <h2 className="ft-medium mb-4 font-Horizon text-theme2">
                    A RESPONSIBLE
                    <br />
                    REVOLUTION
                  </h2>
                  <p className="mb-4" style={{ color: "#FFFFFF" }}>
                    It’s simple. You! And everything you stand for. Whether it’s
                    a recipe that you believe will inspire a new taste or a
                    life-altering message that you believe would inspire
                    generations, the Dared2 Clan welcomes all those who dare to
                    shake things up. Making fancy vape apparatuses isn’t so much
                    on our mind (although we do it spectacularly well), as it is
                    to give you a symbol of defiance against the norms.
                    Something you can hold on to as you go on to change the
                    world.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="middle pb-20">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div className="card__custom">
                  <img
                    src="/assets/images/5.jpg"
                    alt=""
                    className="img-fluid"
                    style={{ borderRadius: "20px" }}
                  />
                  <div className="card__sub">
                    <h3>OUR BRAND</h3>
                    <Link to="/about-us">Learn More {">"}</Link>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div
                  className="card__custom"
                  style={{ marginTop: isMobile ? "20px" : "" }}
                >
                  <img
                    src="/assets/images/6.jpg"
                    alt=""
                    className="img-fluid"
                    style={{ borderRadius: "20px" }}
                  />
                  <div className="card__sub">
                    <h3>INNOVATIONS</h3>
                    <Link to="/innovation">Learn More {">"}</Link>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div
                  className="card__custom"
                  style={{ marginTop: isMobile ? "20px" : "" }}
                >
                  <img
                    src="/assets/images/7.jpg"
                    alt=""
                    className="img-fluid"
                    style={{ borderRadius: "20px" }}
                  />
                  <div className="card__sub">
                    <h3>BLOGS</h3>
                    <Link to="/blogs">Learn More {">"}</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Link
        data-toggle="modal"
        data-target="#mdlPopup"
        className="text-underline"
        id="a_mdlPopup"
        style={{ display: "none" }}
      ></Link>

      <div
        className="modal fade sm-modal"
        id="mdlPopup"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="quickviewmodal"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-xl login-pop-form" role="document">
          <div className="modal-content" id="quickviewmodal">
            {/* <div className="modal-headers">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="ti-close"></span>
              </button>
            </div> */}

            <div className="modal-body">
              <h6 className="text-center font-weight">AGE VERIFICATION</h6>
              <p className="text-center">
                To use the Vaporesso website you must be aged 21 years or over.
                Please verify your age before entering the site.
                <br />
                We use cookies to improve our website and your experience
                browsing it. By continuing to browse our website you accept our
                Cookie Policy.
              </p>

              <p className="text-center" style={{ display: "flex" }}>
                <div
                  data-dismiss="modal"
                  style={{
                    border: "1px solid #000",
                    borderRadius: "20px",
                    width: "100px",
                    marginLeft: isMobile ? "20px" : "100px",
                    cursor: "pointer",
                  }}
                  onClick={handleAge}
                >
                  Under 21
                </div>
                <div
                  data-dismiss="modal"
                  style={{
                    marginLeft: "100px",
                    border: "1px solid green",
                    borderRadius: "20px",
                    width: "100px",
                    cursor: "pointer",
                  }}
                  onClick={handleAge}
                >
                  21+
                </div>
              </p>

              <p className="text-center" style={{ color: "red" }}>
                WARNING:
                <br />
                This product contains nicotine. Nicotine is an addictive
                chemical.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <Modal isOpen={isModalOpen} onClose={closeModal} /> */}
    </>
  );
};
export default HomeScreen;
