import React, { useState, useEffect } from "react";
// import { isMobile } from "react-device-detect";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const ProductScreen = () => {
  const [isMobile, setIsMobile] = useState(getDeviceType());
  function getDeviceType() {
    return window.innerWidth > 768 ? false : true;
  }
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(getDeviceType());
    };

    // Attach the event listener for resize
    window.addEventListener("resize", handleResize);

    // Cleanup: remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const bannerOptions = {
    loop: true,
    center: true,
    //items: 6,
    margin: 0,
    autoplay: true,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: true,
    navText: [
      '<i class="fa fa-angle-left" aria-hidden="true" style="color: #fff; font-size: 40px"></i>',
      '<i class="fa fa-angle-right" aria-hidden="true" style="color: #fff; font-size: 40px"></i>',
    ],
    navContainer: ".main-content .custom-nav",
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 5,
      },
    },
  };

  return (
    <>
      <section
        className="middle product-bg-1"
        style={{ background: "#000000 0% 0% no-repeat padding-box" }}
      >
        <div className="container">
          <div className="row text-center">
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">&nbsp;</div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <span
                style={{ color: "#60DB42", paddingRight: "20px" }}
                className="text-center"
              >
                POD
              </span>
              <span
                style={{ color: "#fff", paddingRight: "20px" }}
                className="text-center"
              >
                POD MOD
              </span>
              <span
                style={{ color: "#fff", paddingRight: "20px" }}
                className="text-center"
              >
                TANK MOD
              </span>
              <span style={{ color: "#fff" }} className="text-center">
                PEN STYLE
              </span>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">&nbsp;</div>
          <div className="clear-fix">&nbsp;</div>
          <div className="main-content row align-items-center justify-content-between">
            <OwlCarousel className="owl-carousel owl-theme" {...bannerOptions}>
              {/* <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 text-right">
                {!isMobile && (
                  <i
                    class="fas fa-angle-left"
                    style={{ color: "#fff", fontSize: "60px" }}
                  ></i>
                )}
                ;
              </div> */}

              <div className="item">
                <img
                  src="/assets/images/Mask Group 1.png"
                  className="card"
                  style={{ width: "100%", borderRadius: "20px" }}
                  alt=""
                />
                <p style={{ color: "#60DB42" }} className="text-center">
                  KINGPIN
                </p>
              </div>
              <div className="item">
                <img
                  src="/assets/images/Mask Group 2.png"
                  className="card"
                  style={{ width: "100%", borderRadius: "20px" }}
                  alt=""
                />
                <p style={{ color: "#fff" }} className="text-center">
                  LUXE Q2 SE
                </p>
              </div>
              <div className="item">
                <img
                  src="/assets/images/Mask Group 3.png"
                  className="card"
                  style={{ width: "100%", borderRadius: "20px" }}
                  alt=""
                />
                <p style={{ color: "#fff" }} className="text-center">
                  ECO NANO
                </p>
              </div>
              <div className="item">
                <img
                  src="/assets/images/Mask Group 4.png"
                  className="card"
                  style={{ width: "100%", borderRadius: "20px" }}
                  alt=""
                />
                <p style={{ color: "#fff" }} className="text-center">
                  VAPORESSO COSS
                </p>
              </div>
              {/* <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                {!isMobile && (
                  <i
                    class="fas fa-angle-right"
                    style={{ color: "#fff", fontSize: "60px" }}
                  ></i>
                )}
              </div> */}
            </OwlCarousel>
            <div class="owl-theme">
              <div class="owl-controls">
                <div class="custom-nav owl-nav"></div>
              </div>
            </div>
          </div>

          <div className="clear-fix">&nbsp;</div>
          {/* <div className="row text-center">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <p style={{ color: "#60DB42" }}>Learn More {">"}</p>
            </div>
          </div> */}
        </div>
      </section>
      <section className="middle product-bg-2">
        <div className="container">
          <div className="row text-center">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <h2
                className="text-theme2 font-Horizon"
                style={{ fontSize: isMobile ? "23px" : "60px" }}
              >
                THE KINGPIN SERIES
              </h2>
              <br />
              <p className="text-white text-center">
                IF ROYALTY IS YOUR THING, THE KINGPIN VAPES ARE YOUR CROWN
                JEWELS. FORGED BY INNOVATION AND DEFINED BY ATTITUDES,
                {isMobile ? "" : <br />}
                THESE VAPES ARE MANIFESTATIONS OF SELF-EXPRESSION, INVITING THE
                KINGS AND QUEENS OF DEFIANCE TO EMBRACE THEIR
                {isMobile ? "" : <br />}
                INNER NON CONFORMISTS AND SET NEW TRENDS FOR THE WORLD TO
                FOLLOW.
              </p>
              {/* <a
                href="#!"
                className="btn stretched-link borders explore-btn"
                style={{ marginTop: "4%" }}
              >
                ENQUIRE NOW
              </a> */}
            </div>
          </div>
        </div>
      </section>

      <section className="middle product-bg-1" style={{ paddingBottom: "0px" }}>
        <div className="container">
          <div className="row text-center">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <h2 className="text-white text-center font-Horizon">
                PRODUCT SPECIFICATION
              </h2>
            </div>
          </div>
          <div className="clear-fix">&nbsp;</div>
          <div className="row no-gutters">
            <div className="col-xl-1 col-lg-1 col-md-1 col-sm-10">&nbsp;</div>
            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10">
              <div className="row no-gutters exlio_gutters">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="row no-gutters">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <div className="single_cats">
                        <a
                          href="#!"
                          class="cards card-overflow card-scale md_height"
                        >
                          <div
                            class="bg-image card"
                            style={{
                              background: "#252525 0% 0% no-repeat padding-box",
                            }}
                          ></div>
                          <div class="ct_body">
                            <div class="ct_body_caption left">
                              <p className="text-white">Color</p>
                              <h5 className="text-white">
                                0.6Ω mesh pod (2ml)
                              </h5>
                              <img
                                src="/assets/images/balls.png"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            {/* <div class="ct_footer left">
                          <span class="stretched-link fs-md">
                            Browse Items <i class="ti-arrow-circle-right"></i>
                          </span>
                        </div> */}
                          </div>
                        </a>
                      </div>

                      <div className="single_cats">
                        <a
                          href="#!"
                          class="cards card-overflow card-scale md_height"
                        >
                          <div
                            class="bg-image card"
                            style={{
                              background: "#252525 0% 0% no-repeat padding-box",
                            }}
                          ></div>
                          <div class="ct_body">
                            <div class="ct_body_caption left">
                              <p className="text-white">MSRP</p>
                              <h5 className="text-white">
                                $ 11.9 (2pcs)
                                <br />$ 21.9 (4pcs)
                              </h5>
                            </div>
                            {/* <div class="ct_footer left">
                          <span class="stretched-link fs-md">
                            Browse Items <i class="ti-arrow-circle-right"></i>
                          </span>
                        </div> */}
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-xl-6 col-lg-6 col-md-6 col-sm-12 product-bg-3 "
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  <div className="prd-1-bg">
                    {/* <img
                      src="/assets/images/Group 177.png"
                      alt=""
                      className="img-fluid product_img"
                    /> */}
                  </div>
                  {/* <div className="row no-gutters ">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
                      <div className="single_cats ">
                        
                      </div>
                    </div>
                  </div> */}
                </div>
                {/* <div
              className="col-xl-2 col-lg-2 col-md-2 col-sm-12 card"
              style={{ background: "#252525 0% 0% no-repeat padding-box" }}
            >
              <p className="text-white">Color</p>
              <h5 className="text-white">0.6Ω mesh pod (2ml)</h5>
              <img
                src="/assets/images/balls.png"
                alt=""
                className="img-fluid"
              />
            </div> */}
              </div>
            </div>
            <div className="col-xl-1 col-lg-1 col-md-1 col-sm-10">&nbsp;</div>
          </div>

          <div className="clear-fix">&nbsp;</div>
          <div className="row text-center">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <img
                src="/assets/images/Group 188.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
          <div className="clear-fix">&nbsp;</div>

          {/* <div className="row text-center ">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <img
                src="/assets/images/Mask Group 10.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
          <div className="row text-center">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <img
                src="/assets/images/Mask Group 8.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
          <div className="row no-gutters text-center">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <img
                src="/assets/images/Group 124.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div> */}
        </div>

        <div className="container" style={{ marginTop: "40px" }}>
          <div className="row text-center">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <h1
                className="text-white text-center font-Horizon text-theme"
                style={{
                  fontSize: isMobile ? "35px" : "",
                }}
              >
                KEY FEATURES
              </h1>
            </div>
          </div>
        </div>
        <section className="Mask-Group-10-bg">
          <div className="container">
            <div className="row text-center">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <h3
                  className="text-white text-left font-Horizon"
                  style={{
                    marginTop: isMobile ? "-6%" : "6%",
                    marginLeft: isMobile ? "0%" : "33%",
                    textTransform: "uppercase",
                    fontSize: isMobile ? "17px" : "",
                  }}
                >
                  Unprecedented
                  <br />
                  Puff Capacity
                </h3>

                <h3
                  className="text-white text-left font-Horizon"
                  style={{
                    marginTop: "3%",
                    marginLeft: isMobile ? "0%" : "30%",
                    textTransform: "uppercase",
                    fontSize: isMobile ? "17px" : "",
                  }}
                >
                  Compact and
                  <br />
                  Lightweight
                </h3>
                <h3
                  className="text-white text-left font-Horizon"
                  style={{
                    marginTop: "3%",
                    marginLeft: isMobile ? "0%" : "24%",
                    textTransform: "uppercase",
                    fontSize: isMobile ? "17px" : "",
                  }}
                >
                  Intuitive Touch
                  <br />
                  Controls
                </h3>

                <h3
                  className="text-white text-left font-Horizon"
                  style={{
                    marginTop: "3%",
                    marginLeft: isMobile ? "0%" : "23%",
                    textTransform: "uppercase",
                    fontSize: isMobile ? "17px" : "",
                  }}
                >
                  Sleek and
                  <br />
                  Modern Design
                </h3>

                <h3
                  className="text-white text-left font-Horizon"
                  style={{
                    marginTop: isMobile ? "-3%" : "3%",
                    marginLeft: isMobile ? "0%" : "26%",
                    textTransform: "uppercase",
                    fontSize: isMobile ? "17px" : "",
                  }}
                >
                  Short Circuit
                  <br />
                  Control
                </h3>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="Mask-Group-8-bg"></section>
        <section className="Group-124-bg"></section> */}
        <section
          className="middle product-bg-1"
          style={{
            height: isMobile ? "33vh" : "98vh",
            paddingTop: isMobile ? "50px" : "90px",
          }}
          // style={{ background: "#000000 0% 0% no-repeat padding-box" }}
        >
          <div className="container">
            <div className="row text-center">
              <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12"></div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                {/* {!isMobile && (
                  <i
                    class="fas fa-angle-left"
                    style={{ color: "#fff", fontSize: "60px" }}
                  ></i>
                )} */}

                <img
                  src="/assets/images/Mask Group 9.png"
                  className="img-fluid"
                  alt=""
                  style={{ borderRadius: "15px" }}
                />
                <img
                  src="/assets/images/Mask Group 11.png"
                  className="img-fluid kit-second-image"
                  alt=""
                />
                {/* {!isMobile && (
                  <i
                    class="fas fa-angle-right"
                    style={{ color: "#fff", fontSize: "60px" }}
                  ></i>
                )} */}
              </div>
              <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12"></div>
              {/* <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">&nbsp;</div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 card">
              <div className="row no-gutters">
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 display-flex">
                  <img
                    src="/assets/images/Mask Group 2.png"
                    className="img-fluid"
                    alt=""
                  />
                  <img
                    src="/assets/images/pod-p.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                  <h5 className="font-weight">KIT INCLUDES</h5>
                  <ul className="text-left" style={{ listStyle: "disc" }}>
                    <li>Vaporization Efficiency Ratio</li>
                    <li>Flavor Reproduction</li>
                    <li>Pod Lifespan</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">&nbsp;</div> */}
            </div>
          </div>
        </section>
      </section>
    </>
  );
};
export default ProductScreen;
