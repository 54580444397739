import React, { useEffect, useState } from "react";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
// import Modal from "../components/Modal/Modal";

const HomeScreen1 = () => {
  const bannerOptions = {
    loop: true,
    center: true,
    items: 1,
    margin: 0,
    autoplay: true,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  // const [isModalOpen, setIsModalOpen] = useState(true);
  // const openModal = () => {
  //   setIsModalOpen(true);
  // };

  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  // useEffect(() => {
  //   setIsModalOpen(true);
  // }, []);

  return (
    <>
      {/* <OwlCarousel className="owl-carousel owl-theme" {...bannerOptions}>
        <div className="item no__padding">
          <img src="/assets/images/banner/banner-1.jpg" alt="banner" />
        </div>
      </OwlCarousel> */}

      <div className="home-slider hide-navigation margin-bottom-0">
        <div
          data-background-image="/assets/images/banner/banner-1.jpg"
          className="item"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="home-slider-container">
                  <div className="home-slider-desc">
                    <div className="home-slider-title mb-4">
                      <h2 className="mb-1 ft-bold lg-heading text-theme">
                        PRODUCT NAME
                      </h2>
                      <h3 className="text-white">PRODUCT CATEGORY</h3>

                      <span className="text-white">
                        Redefine the industry’s low carbon future
                      </span>
                    </div>
                    Learn More {">"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          data-background-image="/assets/images/banner/banner-1.jpg"
          className="item"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="home-slider-container">
                  <div className="home-slider-desc">
                    <div className="home-slider-title mb-4">
                      <h2 className="mb-1 ft-bold lg-heading text-theme">
                        PRODUCT NAME
                      </h2>
                      <h3 className="text-white">PRODUCT CATEGORY</h3>

                      <span className="text-white">
                        Redefine the industry’s low carbon future
                      </span>
                    </div>
                    Learn More {">"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="middle pb-20 bg-1">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <img
                src="/assets/images/product/1.jpg"
                alt=""
                className="img-fluid"
                style={{ borderRadius: "25px" }}
              />
              <div>
                <h3>POD</h3>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <img
                src="/assets/images/product/2.jpg"
                alt=""
                className="img-fluid"
                style={{ borderRadius: "25px" }}
              />
            </div>
          </div>
          <div
            className="row justify-content-center"
            style={{ marginTop: "40px" }}
          >
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <img
                src="/assets/images/product/3.jpg"
                alt=""
                className="img-fluid"
                style={{ borderRadius: "25px" }}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <img
                src="/assets/images/product/4.jpg"
                alt=""
                className="img-fluid"
                style={{ borderRadius: "25px" }}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="middle pb-20 no__padding bg-2">
        <div className="item no__padding">
          <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">&nbsp;</div>

          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 card">
            <img
              src="/assets/images/after-sales.png"
              alt=""
              className="img-fluid"
            />
            {/* <div className="col-2">AFTER SALES</div>
            <div className="col-2">PRODUCT AUTHENTICATION</div>
            <div className="col-2">MANUALS & FIRMWARES</div> */}
          </div>

          <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">&nbsp;</div>
          {/* <img
            src="/assets/images/banner/2.jpg"
            className="img-fluid"
            alt="banner"
          /> */}
        </div>

        {/* <div className="container">
          <div className="row justify-content-center">
            <img
              src="/assets/images/banner/2.jpg"
              alt=""
              className="img-fluid"
            />
          </div>
        </div> */}
      </section>

      <section className="middle pb-20 no__padding">
        <div className="item no__padding">
          <div className="bg-3-content">
            <h5 style={{ color: "#fff" }}>PLATFORM</h5>
            <img src="/assets/images/zero-pod.png" alt="ZERO POD" />
            {/* <h2 style={{ color: "#4CD9FF", fontWeight: "bold" }}>ZERO POD</h2> */}
            <h4 style={{ color: "#fff" }}>START FROM ZERO</h4>
            <p style={{ color: "#fff" }}>Learn More {">"}</p>
          </div>
          <img
            src="/assets/images/banner/3.jpg"
            className="img-fluid"
            alt="banner"
          />
        </div>
      </section>

      <section className="middle pb-20 bg-1">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <div className="card__custom">
                <img
                  src="/assets/images/5.jpg"
                  alt=""
                  className="img-fluid"
                  style={{ borderRadius: "25px" }}
                />
                <div className="card__sub">
                  <h3>OUR BRAND</h3>
                  <Link to="/">Learn More {">"}</Link>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <div className="card__custom">
                <img
                  src="/assets/images/6.jpg"
                  alt=""
                  className="img-fluid"
                  style={{ borderRadius: "25px" }}
                />
                <div className="card__sub">
                  <h3>INNOVATIONS</h3>
                  <Link to="/">Learn More {">"}</Link>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <div className="card__custom">
                <img
                  src="/assets/images/7.jpg"
                  alt=""
                  className="img-fluid"
                  style={{ borderRadius: "25px" }}
                />
                <div className="card__sub">
                  <h3>BLOGS</h3>
                  <Link to="/">Learn More {">"}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <a
        href="#!"
        data-toggle="modal"
        data-target="mdlPopup"
        className="text-underline"
      >
        Open Popup
      </a> */}

      <div
        className="modal fade lg-modal"
        id="mdlPopup"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="quickviewmodal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl login-pop-form" role="document">
          <div className="modal-content" id="quickviewmodal">
            <div className="modal-headers">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="ti-close"></span>
              </button>
            </div>

            <div className="modal-body">
              <h3>Age Verification</h3>
            </div>
          </div>
        </div>
      </div>

      {/* <Modal isOpen={isModalOpen} onClose={closeModal} /> */}
    </>
  );
};
export default HomeScreen1;
