// Reducer function
export const CounterReducer = (state, action) => {
  switch (action.type) {
    case "INCREMENT":
      return { ...state, counter: state.counter + 1 };
    case "DECREMENT":
      if (state.counter === 0) return { ...state, counter: state.counter };
      return { ...state, counter: state.counter - 1 };
    default:
      return state;
  }
};
