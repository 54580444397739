import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const BlogScreen = () => {
  const [isMobile, setIsMobile] = useState(getDeviceType());
  function getDeviceType() {
    return window.innerWidth > 768 ? false : true;
  }
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(getDeviceType());
    };

    // Attach the event listener for resize
    window.addEventListener("resize", handleResize);

    // Cleanup: remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("selectedAge"))
      document.getElementById("a_mdlPopup").click();
    //setIsModalOpen(true);
  }, []);

  const handleAge = () => {
    localStorage.setItem("selectedAge", "Y");
  };

  return (
    <>
      <div className="bg-1">
        <section
          className="middle"
          // style={{ background: "#000000 0% 0% no-repeat padding-box" }}
        >
          <div className="container">
            <div className="row text-center">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <h1 className="text-theme font-Horizon">BLOGS</h1>
              </div>
            </div>
          </div>
        </section>
        <section
          className="middle"
          // style={{ background: "#000000 0% 0% no-repeat padding-box" }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div
                  className="first_about_border"
                  style={{ height: "135%" }}
                ></div>
                <div className="abt_caption first_about">
                  <h2 className="ft-medium mb-4 font-Horizon text-theme3">
                    HOW TO CLEAN VAPE
                  </h2>
                  <p className="mb-4" style={{ color: "#FFFFFF" }}>
                    A bunch of friends, back in 2016, dreaming of a better way
                    to enjoy nicotine. We weren’t experts. We were just regular
                    folks, with rebellious souls and a shared vision for a
                    healthier, tastier, and easier way to curb our nicotine
                    cravings. That’s when Dared2 came to life. Fast forward to
                    today, and we’ve grown into a coveted name in the vaping
                    world.
                  </p>
                  <button className="btn_continue_reading">
                    Continue Reading
                  </button>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="abt_caption ">
                  <img
                    src="/assets/images/blog/Group 197.png"
                    className="img-fluid rounded"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="middle"
          // style={{ background: "#000000 0% 0% no-repeat padding-box" }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="abt_caption abt_caption_image">
                  <img
                    src="/assets/images/blog/Group 205.png"
                    className="img-fluid rounded"
                    alt=""
                  />
                </div>
              </div>

              <div
                className="col-xl-6 col-lg-6 col-md-6 col-sm-12"
                style={{ marginTop: isMobile && "20px" }}
              >
                <div
                  className="second_about_border"
                  style={{ height: "142%" }}
                ></div>
                <div className="abt_caption second_about">
                  <h2 className="ft-medium mb-4 font-Horizon text-theme2">
                    HOW TO SMOKE VAPE
                  </h2>
                  <p className="mb-4" style={{ color: "#FFFFFF" }}>
                    At DARED2, we’re all about excellence in vaping. From easy
                    starter kits to advanced mods and custom e-liquids, we’ve
                    got something for anyone who dares to think differently and
                    challenge the norms. Rest assured, your vaping journey will
                    get a whole lot cooler with us, and who knows, even life
                    changing.
                  </p>
                  <button className="btn_continue_reading">
                    Continue Reading
                  </button>
                </div>
              </div>
            </div>
            <div
              className="row align-items-center justify-content-between"
              style={{ paddingTop: "100px" }}
            >
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="third_about_border"></div>
                <div className="abt_caption first_about">
                  <h2 className="ft-medium mb-4 font-Horizon text-theme">
                    E-LIQUID GUIDE
                  </h2>
                  <p className="mb-4" style={{ color: "#FFFFFF" }}>
                    Think of us as your trusted comrades on your vaping journey.
                    We’re all about keeping it real through integrity,
                    transparency, and making sure you never leave unhappy. We
                    source our ingredients from trusted suppliers, ensuring that
                    every puff you take is free from harmful chemicals and
                    contaminants.
                  </p>
                  <button className="btn_continue_reading">
                    Continue Reading
                  </button>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="abt_caption ">
                  <img
                    src="/assets/images/blog/Group 243.png"
                    className="img-fluid rounded"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="middle pb-20"
          // style={{ background: "#000000 0% 0% no-repeat padding-box" }}
        >
          <div className="container">
            <div className="row" style={{ paddingTop: "40px" }}>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <h4 className="text-theme2 font-Horizon">AUTHORS</h4>
                <p>
                  <strong style={{ color: "#FF0000" }}>Rahul</strong>
                  <p className="text-white">
                    The use of this product contains nicotine, ….
                  </p>
                  <strong style={{ color: "#FF0000" }}>Arun</strong>
                  <p className="text-white">
                    Keep this product out of the reach of ….
                  </p>
                  <strong style={{ color: "#FF0000" }}>Neeraj</strong>
                  <p className="text-white">
                    Vaping can expose users to harmful ….
                  </p>
                  <strong style={{ color: "#FF0000" }}>Mohsin</strong>
                  <p className="text-white">
                    Do not use this product while driving or….
                  </p>{" "}
                  <strong style={{ color: "#FF0000" }}>Vishal</strong>
                  <p className="text-white">
                    Some individuals may be sensitive or…
                  </p>
                </p>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-4 col-sm-12"
                style={{ paddingTop: isMobile ? "30px" : "" }}
              >
                <h4 className="text-theme2 font-Horizon">CATEGORIES</h4>
                <p>
                  <strong style={{ color: "#FF0000" }}>
                    Popular Vape Devices
                  </strong>
                  <p className="text-white">
                    The use of this product contains nicotine….
                  </p>
                  <strong style={{ color: "#FF0000" }}>E-Liquid Guide:</strong>
                  <p className="text-white">
                    Keep this product out of the reach of …..
                  </p>
                  <strong style={{ color: "#FF0000" }}>
                    {" "}
                    How To Smoke Vape
                  </strong>
                  <p className="text-white">
                    Vaping can expose users to harmful …..
                  </p>
                  <strong style={{ color: "#FF0000" }}> Guide to Vaping</strong>
                  <p className="text-white">
                    Do not use this product while driving or ….
                  </p>{" "}
                  <strong style={{ color: "#FF0000" }}>
                    Vape Battery Safety
                  </strong>
                  <p className="text-white">
                    Some individuals may be sensitive or….
                  </p>
                </p>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-4 col-sm-12"
                style={{ paddingTop: isMobile ? "30px" : "" }}
              >
                <h4 className="text-theme2 font-Horizon">FEATURED ARTICLES</h4>
                <p className="text-white">Why is my Vape Pen Blinking?</p>
                <p className="text-white">
                  How Much Nicotine Is In Vape e-Juice?
                  <br />
                  How To Smoke Vape
                </p>
                <p className="text-white">
                  How Long Do Vape Coils Last And How To Make Them Last
                  LongerVape Battery Safety
                </p>
                <p className="text-white">
                  45 Celebrities Who Vape From Every Field– You Never Know
                </p>{" "}
                <p className="text-white">
                  Vaping 101: Which Vape Juice Ingredients to Avoid
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Link
        data-toggle="modal"
        data-target="#mdlPopup"
        className="text-underline"
        id="a_mdlPopup"
        style={{ display: "none" }}
      ></Link>

      <div
        className="modal fade sm-modal"
        id="mdlPopup"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="quickviewmodal"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-xl login-pop-form" role="document">
          <div className="modal-content" id="quickviewmodal">
            {/* <div className="modal-headers">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="ti-close"></span>
              </button>
            </div> */}

            <div className="modal-body">
              <h6 className="text-center font-weight">AGE VERIFICATION</h6>
              <p className="text-center">
                To use the Vaporesso website you must be aged 21 years or over.
                Please verify your age before entering the site.
                <br />
                We use cookies to improve our website and your experience
                browsing it. By continuing to browse our website you accept our
                Cookie Policy.
              </p>

              <p className="text-center" style={{ display: "flex" }}>
                <div
                  data-dismiss="modal"
                  style={{
                    border: "1px solid #000",
                    borderRadius: "20px",
                    width: "100px",
                    marginLeft: isMobile ? "20px" : "100px",
                    cursor: "pointer",
                  }}
                  onClick={handleAge}
                >
                  Under 21
                </div>
                <div
                  data-dismiss="modal"
                  style={{
                    marginLeft: "100px",
                    border: "1px solid green",
                    borderRadius: "20px",
                    width: "100px",
                    cursor: "pointer",
                  }}
                  onClick={handleAge}
                >
                  21+
                </div>
              </p>

              <p className="text-center" style={{ color: "red" }}>
                WARNING:
                <br />
                This product contains nicotine. Nicotine is an addictive
                chemical.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BlogScreen;
